import React from "react";
import CookieConsent from "react-cookie-consent";

import { Colors } from "../../Helpers";
import { Text } from "../../Components";

const Element = () => (
  <CookieConsent
    location="bottom"
    buttonText="Okay"
    cookieName="consent"
    style={{ background: Colors.russian }}
    buttonStyle={{
      padding: "0 24px",
      lineHeight: "52px",
      color: Colors.russian,
      backgroundColor: Colors.white,
      borderRadius: 6,
      fontWeight: "600",
      fontSize: 16,
      fontFamily: "Gilroy",
    }}
    expires={150}
  >
    <Text color={Colors.white} size={18}>
      This website uses cookies to enhance the user experience.
    </Text>
  </CookieConsent>
);

export default Element;
