import React from "react";

import {
  StaticContainer,
  NavbarContainer,
  FooterContainer,
} from "../Containers";
import { API, Resources } from "../Modules";

import { Consent } from "../Components";

class Component extends React.PureComponent {
  componentDidMount() {
    var key;

    switch (this.props.match.params.static) {
      case "privacy-policy":
        key = Resources.PRIVACY_POLICY;
        break;
      case "terms-and-conditions":
        key = Resources.TERMS_AND_CONDITIONS;
        break;
      default:
        key = Resources.PRIVACY_POLICY;
    }

    API.static(key);
  }

  render() {
    return (
      <div>
        <NavbarContainer no-blog />
        <StaticContainer />
        <FooterContainer />
        <Consent />
      </div>
    );
  }
}

export default Component;
