import React from "react";
import { Container, Row, Col } from "react-grid-system";
import {
  Header,
  Paragraph,
  Button,
  Subtitle,
  Text,
  Align,
} from "../Components";
import { Colors, Types } from "../Helpers";
import { API } from "../Modules";

class Component extends React.PureComponent {
  render() {
    return (
      <Container>
        <Row align="center" justify="center">
          <Col xs={12} sm={12} md={8} lg={6}>
            <Subtitle bottom={16}>Random Sites</Subtitle>
            <Header bottom={32}>Sites for every mood</Header>
            <Paragraph bottom={48}>
              Want to kill time? Get surprised? Learn new things or have fun?
              Random Sites is your correct address.
            </Paragraph>
            <Row style={{ marginBottom: 24 }}>
              <Col x={6}>
                <Button
                  type="large"
                  background="rgba(0,194,209,0.4)"
                  color={Colors.black}
                  click={() => API.websites(Types.consuming)}
                >
                  <Align direction="column">
                    <Text size={12} line={18}>
                      I Want To
                    </Text>
                    <Text>Kill Time</Text>
                  </Align>
                </Button>
              </Col>
              <Col x={6}>
                <Button
                  type="large"
                  background="rgba(249,233,0,0.4)"
                  color={Colors.black}
                  click={() => API.websites(Types.helpful)}
                >
                  <Align direction="column">
                    <Text size={12} line={18}>
                      I Want To
                    </Text>
                    <Text>Learn</Text>
                  </Align>
                </Button>
              </Col>
            </Row>
            <Row>
              <Col x={6}>
                <Button
                  type="large"
                  background="rgba(245,93,63,0.4)"
                  color={Colors.black}
                  click={() => API.websites(Types.fun)}
                >
                  <Align direction="column">
                    <Text size={12} line={18}>
                      I Want To
                    </Text>
                    <Text>Have Fun</Text>
                  </Align>
                </Button>
              </Col>
              <Col x={6}>
                <Button
                  type="large"
                  background={Colors.russian}
                  click={() => API.websites(Types.interesting)}
                >
                  <Align direction="column">
                    <Text size={12} line={18} color={Colors.white}>
                      I Want To
                    </Text>
                    <Text color={Colors.white}>Be Suprised</Text>
                  </Align>
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Component;
