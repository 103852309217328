import Airtable from "airtable";
import Lodash from "lodash";
import Store from "./Store";

async function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export default class API {
  static websites(type) {
    const Base = new Airtable({ apiKey: "keyUP5hmhj7dQDdN3" }).base(
      "appVLOZs4kEZLZDFa"
    );

    Base("Websies")
      .select({
        view: "Live",
        filterByFormula: "{Type} = '" + type + "'",
      })
      .firstPage(function (error, records) {
        const Record = Lodash.sample(records);
        var URL = Record.get("URL");

        URL = URL.trim();

        if (!URL.includes("http://") && !URL.includes("https://")) {
          URL = "http://" + URL;
        }

        console.log(URL);

        try {
          var Window = window.open(URL, "_blank");
          Window.focus();
        } catch (e) {
          var WindowMobile = window.open();
          WindowMobile.location = URL;
          WindowMobile.focus();
        } finally {

        }
      });
  }

  static blogs() {
    const Base = new Airtable({ apiKey: "keyUP5hmhj7dQDdN3" }).base(
      "appfx4jJ7E9utYOl3"
    );

    Base("Blogs")
      .select({
        filterByFormula: "{Project} = 'Random Sites'",
      })
      .firstPage(function (error, records) {
        var blogs = [];

        records.map((record) => {
          var blog = {};
          blog.id = record.id;
          blog.title = record.get("Title");
          blog.content = record.get("Content");
          blog.src = record.get("Attachment")[0].url;
          blogs.push(blog);
          return true;
        });

        Store.dispatch({ type: "CORE", key: "blogs", value: blogs });
      });

    return true;
  }

  static post(id) {
    const Base = new Airtable({ apiKey: "keyUP5hmhj7dQDdN3" }).base(
      "appfx4jJ7E9utYOl3"
    );

    Base("Blogs").find(id, function (error, record) {
      var blog = {};
      blog.id = record.id;
      blog.title = record.get("Title");
      blog.content = record.get("Content");
      blog.src = record.get("Attachment")[0].url;

      Store.dispatch({ type: "CORE", key: "blog", value: blog });
    });

    return true;
  }

  static static(key) {
    const Base = new Airtable({ apiKey: "keyUP5hmhj7dQDdN3" }).base(
      "app02iJcuHjpO7vyR"
    );

    Base("Static")
      .select({
        filterByFormula: "{Key} = '" + key + "'",
      })
      .firstPage(function (err, records) {
        records.forEach(function (record) {
          Store.dispatch({
            type: "CORE",
            key: "static",
            value: {
              title: record.get("Title"),
              content: record.get("Content"),
            },
          });
        });
      });

    return true;
  }
}
